import React from "react";
import classNames from "classnames";

const CardBody = ({ children, className, ...props }) => {
  const cls = classNames("card__body", className);
  return (
    <div className={cls} {...props}>
      {children}
    </div>
  );
};

export default CardBody;

import React from "react";
import classNames from "classnames";

const DropdownMenu = ({
  children,
  className,
  right,
  triggerSize = "sm",
  triggerIconClass = "ico--dots-three-vertical",
}) => {
  const wrapperCls = classNames(
    "dropdown",
    {
      "dropdown--right": !!right,
    },
    className
  );

  const triggerCls = classNames(
    "cursor-pointer ml-auto text-grey-200 hover:text-black",
    `text-${triggerSize}`,
    triggerIconClass
  );

  return (
    <div className={wrapperCls}>
      <i className={triggerCls}></i>
      <ul className="dropdown__content whitespace-no-wrap">{children}</ul>
    </div>
  );
};

export default React.memo(DropdownMenu);

import { createAsyncAction, errorResult, successResult } from "pullstate";

import { GlobalInfoStore } from "stores";
import { connect } from "ws/connection";

import { loadAnnouncements } from "./announcements";
import { loadPosts } from "./posts";
import { loadProgram } from "./program";

export const initializeWSChannel = createAsyncAction(async () => {
  const { websocketUrl } = GlobalInfoStore.getRawState();

  try {
    const wsChannel = await connect({
      url: websocketUrl,
      onConnect: async ({ worker }) => {
        // Re-load initial data once connected, this will ensure we won't lose
        // any intermediate state.
        await Promise.all([
          loadProgram.run({}, { respectCache: false }),
          loadAnnouncements.run({}, { respectCache: false }),
          loadPosts.run({}, { respectCache: false }),
        ]);

        // Once loaded, start processing the messages.
        worker.start();

        return true;
      },
    });

    return successResult(wsChannel);
  } catch (err) {
    return errorResult([], err.toString());
  }
});

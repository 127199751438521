import {
  handleAnnouncementChanged,
  handleAnnouncementCreated,
  handleAnnouncementDeleted,
} from "./announcements";
import { handleOnlineUsersUpdated } from "./global";
import {
  handlePostChanged,
  handlePostCreated,
  handlePostDeleted,
  handlePostRanked,
} from "./posts";
import { handleProgramEntryChanged } from "./program";
import {
  handleUserBanned,
  handleUserStatus,
  handleUserUnbanned,
} from "./users";

export const handlers = {
  announcement_changed: handleAnnouncementChanged,
  announcement_created: handleAnnouncementCreated,
  announcement_deleted: handleAnnouncementDeleted,
  post_ranked: handlePostRanked,
  post_changed: handlePostChanged,
  post_created: handlePostCreated,
  post_deleted: handlePostDeleted,
  program_entry_changed: handleProgramEntryChanged,
  user_banned: handleUserBanned,
  user_unbanned: handleUserUnbanned,
  user_status: handleUserStatus,
  online_users_updated: handleOnlineUsersUpdated,
};

import React, { useState } from "react";
import classNames from "classnames";

import { addAnnouncement } from "actions/announcements";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import MarkdownEditor from "components/mde/MarkdownEditor";
import { useActionState } from "hooks";
import { urlRegex } from "utils";

const AddAnnouncementForm = ({ className }) => {
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [textError, setTextError] = useState(null);
  const [linkError, setLinkError] = useState(null);
  const [type, setType] = useState("announcement");

  const [adding, addingError] = useActionState(addAnnouncement, {
    content: text,
    link,
    type,
  });

  const onTextInput = (newText) => {
    setText(newText);

    if (newText !== "") {
      if (newText.length > 1024) {
        setTextError("Maximální délka příspěvku je 1024 znaků.");
      } else {
        setTextError(null);
      }
    }
  };

  const onLinkInput = (newLink) => {
    setLink(newLink);

    if (!!newLink) {
      if (newLink.length > 1024) {
        setLinkError("Maximální délka URL je 256 znaků.");
      } else {
        setLinkError(urlRegex.test(newLink) ? null : "Zadejte platnou URL.");
      }
    }
  };

  const onAdd = async (evt) => {
    evt.preventDefault();

    let preventAction = false;
    const payload = {
      content: text,
      type,
    };

    if (!text) {
      setTextError("Před přidáním oznámení nezapomeňte vyplnit jeho obsah.");
      preventAction = true;
    } else if (!!text && text.length > 1024) {
      setTextError("Maximální délka oznámení je 1024 znaků.");
      preventAction = true;
    }

    if (type === "voting" && !link) {
      setLinkError("Zadejte platnou URL.");
      preventAction = true;
    } else {
      payload.link = link;
    }

    if (preventAction) {
      return;
    }

    const result = await addAnnouncement.run({ content: text, link, type });

    if (!result.error) {
      setText("");
      setLink("");
      setTextError(null);
      setLinkError(null);
    }
  };

  return (
    <form className={className} onSubmit={onAdd}>
      {addingError && (
        <ErrorMessage>
          Při přidávání oznámení došlo k problému: {addingError}.
        </ErrorMessage>
      )}
      <div className="grid grid-cols-1 gap-4">
        <div
          className="form-field"
          onChange={(evt) => setType(evt.target.value)}
        >
          <div className="form-field__wrapper text-sm">
            <div className="radio form-field__control">
              <label>
                <input
                  type="radio"
                  name="announcementType"
                  value="announcement"
                  defaultChecked
                />
                <span>Oznámení</span>
              </label>
            </div>

            <div className="radio form-field__control">
              <label>
                <input type="radio" name="announcementType" value="voting" />
                <span>Rozhodující hlasování</span>
              </label>
            </div>
          </div>
        </div>

        <MarkdownEditor
          value={text}
          onChange={onTextInput}
          error={textError}
          placeholder="Vyplňte text oznámení"
          toolbarCommands={[
            ["bold", "italic", "strikethrough"],
            ["link", "unordered-list", "ordered-list"],
          ]}
          minEditorHeight={100}
        />

        <div
          className={classNames("form-field", {
            hidden: type !== "voting",
            "form-field--error": !!linkError,
          })}
        >
          <div className="form-field__wrapper form-field__wrapper--shadowed">
            <input
              type="text"
              className="text-input text-sm text-input--has-addon-l form-field__control"
              value={link}
              placeholder="URL hlasování"
              onChange={(evt) => onLinkInput(evt.target.value)}
            />
            <div className="text-input-addon text-input-addon--l order-first">
              <i className="ico--link"></i>
            </div>
          </div>
          {!!linkError && <div className="form-field__error">{linkError}</div>}
        </div>
      </div>

      <Button
        type="submit"
        className="text-sm mt-4"
        hoverActive
        loading={adding}
        disabled={textError || linkError || adding}
        fullwidth
      >
        Přidat oznámení
      </Button>
    </form>
  );
};

export default AddAnnouncementForm;

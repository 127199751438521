import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { format, isToday } from "date-fns";

import Chip from "components/Chip";
import { DropdownMenu, DropdownMenuItem } from "components/dropdown-menu";

const Announcement = ({
  className,
  datetime,
  type,
  content,
  link,
  relatedPostId,
  seen,
  canRunActions,
  onDelete,
  onEdit,
  onSeen,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.8,
    trackVisibility: true,
    delay: 1500,
    skip: seen,
    triggerOnce: true,
  });

  useEffect(() => {
    if (!seen && inView && onSeen) {
      onSeen();
    }
  });

  const wrapperClassName = classNames(
    "bg-opacity-50 border-l-2 px-4 py-2 lg:px-8 lg:py-3 transition duration-500",
    {
      "bg-grey-50": !!seen,
      "bg-yellow-100": !seen,
      "border-orange-300": type === "rejected-procedure-proposal",
      "border-blue-300": type === "suggested-procedure-proposal",
      "border-green-400": type === "accepted-procedure-proposal",
      "border-red-600": type === "voting",
      "border-cyan-500": type === "announcement",
      "border-black": type === "user-ban",
    },
    className
  );

  const chipColor = {
    "rejected-procedure-proposal": "orange-300",
    "suggested-procedure-proposal": "blue-300",
    "accepted-procedure-proposal": "green-400",
    voting: "red-600",
    announcement: "cyan-500",
    "user-ban": "black",
  }[type];

  const chipLabel = {
    "rejected-procedure-proposal": "Zamítnutý návrh postupu",
    "suggested-procedure-proposal": "Návrh postupu k hlasování",
    "accepted-procedure-proposal": "Schválený návrh postupu",
    voting: "Rozhodující hlasování",
    announcement: "Oznámení předsedajícího",
    "user-ban": "Zablokovaný účastník jednání",
  }[type];

  const linkLabel =
    type === "voting" ? "Hlasovat" : "Zobrazit související příspěvek";

  const showEdit = [
    "suggested-procedure-proposal",
    "voting",
    "announcement",
  ].includes(type);

  const htmlContent = {
    __html: content,
  };

  return (
    <div className={wrapperClassName} ref={ref}>
      <div className="flex items-center justify-between mb-2">
        <div className="space-x-2 flex items-center">
          <div className="font-bold text-sm">
            {format(datetime, isToday(datetime) ? "H:mm" : "dd. MM. H:mm")}
          </div>
          <Chip color={chipColor} condensed>
            {chipLabel}
          </Chip>
          {link && (
            <a
              href={link}
              className={classNames("text-xs font-bold text-" + chipColor)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkLabel + " »"}
            </a>
          )}
        </div>
        {canRunActions && (
          <DropdownMenu
            right
            className="pl-4"
            triggerIconClass="ico--dots-three-horizontal"
          >
            {showEdit && (
              <DropdownMenuItem
                onClick={onEdit}
                icon="ico--pencil"
                title="Upravit"
              />
            )}
            <DropdownMenuItem
              onClick={onDelete}
              icon="ico--bin"
              title="Smazat"
            />
          </DropdownMenu>
        )}
      </div>
      <div
        className="leading-tight text-sm lg:text-base content-block"
        dangerouslySetInnerHTML={htmlContent}
      ></div>
    </div>
  );
};

export default Announcement;

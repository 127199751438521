import React from "react";
import classNames from "classnames";

const Thumbs = ({ likes, dislikes, myVote, onLike, onDislike, readOnly }) => {
  return (
    <div>
      <div className="space-x-2 text-sm flex items-center">
        <button
          className={classNames("flex items-center space-x-1", {
            "cursor-pointer": !readOnly,
            "cursor-not-allowed": readOnly,
            "text-blue-300": myVote === "like",
            "text-grey-200 ": myVote !== "like",
            "hover:text-blue-300": myVote !== "like" && !readOnly,
          })}
          disabled={readOnly}
          onClick={onLike}
        >
          <span className="font-bold">{likes}</span>
          <i className="ico--thumbs-up"></i>
        </button>
        <button
          className={classNames("flex items-center space-x-1", {
            "cursor-pointer": !readOnly,
            "cursor-not-allowed": readOnly,
            "text-red-600": myVote === "dislike",
            "text-grey-200": myVote !== "dislike",
            "hover:text-red-600": myVote !== "dislike" && !readOnly,
          })}
          disabled={readOnly}
          onClick={onDislike}
        >
          <i className="ico--thumbs-down transform -scale-x-1"></i>
          <span className="font-bold">{dislikes}</span>
        </button>
      </div>
    </div>
  );
};

export default React.memo(Thumbs);

import React, { useState } from "react";
import classNames from "classnames";

import Button from "components/Button";
import { Card, CardActions, CardBody, CardHeadline } from "components/cards";
import ErrorMessage from "components/ErrorMessage";
import MarkdownEditor from "components/mde/MarkdownEditor";
import Modal from "components/modals/Modal";
import { urlRegex } from "utils";

const AnnouncementEditModal = ({
  announcement,
  onCancel,
  onConfirm,
  confirming,
  error,
  ...props
}) => {
  const [text, setText] = useState(announcement.content);
  const [link, setLink] = useState(announcement.link);
  const [textError, setTextError] = useState(null);
  const [linkError, setLinkError] = useState(null);

  const onTextInput = (newText) => {
    setText(newText);

    if (newText !== "") {
      if (newText.length > 1024) {
        setTextError("Maximální délka příspěvku je 1024 znaků.");
      } else {
        setTextError(null);
      }
    }
  };

  const onLinkInput = (newLink) => {
    setLink(newLink);

    if (!!newLink) {
      if (newLink.length > 1024) {
        setLinkError("Maximální délka URL je 256 znaků.");
      } else {
        setLinkError(urlRegex.test(newLink) ? null : "Zadejte platnou URL.");
      }
    }
  };

  const confirm = (evt) => {
    evt.preventDefault();

    let preventAction = false;
    const payload = {
      content: text,
    };

    if (!text) {
      setTextError("Před úpravou oznámení nezapomeňte vyplnit jeho obsah.");
      preventAction = true;
    } else if (!!text && text.length > 1024) {
      setTextError("Maximální délka oznámení je 1024 znaků.");
      preventAction = true;
    }

    if (announcement.type === "voting" && !link) {
      setLinkError("Zadejte platnou URL.");
      preventAction = true;
    } else {
      payload.link = link;
    }

    if (preventAction) {
      return;
    }

    onConfirm(payload);
  };

  return (
    <Modal containerClassName="max-w-lg" onRequestClose={onCancel} {...props}>
      <form onSubmit={confirm}>
        <Card className="elevation-21">
          <CardBody>
            <div className="flex items-center justify-between mb-4">
              <CardHeadline>Upravit oznámení</CardHeadline>
              <button onClick={onCancel} type="button">
                <i className="ico--cross"></i>
              </button>
            </div>
            <MarkdownEditor
              value={text}
              onChange={onTextInput}
              error={textError}
              placeholder="Vyplňte text oznámení"
              toolbarCommands={[
                ["bold", "italic", "strikethrough"],
                ["link", "unordered-list", "ordered-list"],
              ]}
            />
            <div
              className={classNames("form-field mt-4", {
                hidden: announcement.type !== "voting",
                "form-field--error": !!linkError,
              })}
            >
              <div className="form-field__wrapper form-field__wrapper--shadowed">
                <input
                  type="text"
                  className="text-input text-sm text-input--has-addon-l form-field__control"
                  value={link}
                  placeholder="URL hlasování"
                  onChange={(evt) => onLinkInput(evt.target.value)}
                />
                <div className="text-input-addon text-input-addon--l order-first">
                  <i className="ico--link"></i>
                </div>
              </div>
              {!!linkError && (
                <div className="form-field__error">{linkError}</div>
              )}
            </div>
            {error && (
              <ErrorMessage className="mt-2">
                Při editaci došlo k problému: {error}
              </ErrorMessage>
            )}
          </CardBody>
          <CardActions right className="space-x-1">
            <Button
              hoverActive
              color="blue-300"
              className="text-sm"
              loading={confirming}
              disabled={textError || linkError || confirming}
              type="submit"
            >
              Uložit
            </Button>
            <Button
              hoverActive
              color="red-600"
              className="text-sm"
              onClick={onCancel}
              type="button"
            >
              Zrušit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
};

export default AnnouncementEditModal;

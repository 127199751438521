import React from "react";
import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";

import { refreshAccessToken } from "actions/users";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));

function handleVisibilityChange() {
  if (!document.hidden) {
    refreshAccessToken();
  }
}

document.addEventListener("visibilitychange", handleVisibilityChange, false);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
ReactModal.setAppElement(document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";

import Dropdown from "components/Dropdown";
import { PostStore } from "stores";
import { updateWindowPosts } from "utils";

const PostFilters = () => {
  const filters = PostStore.useState((state) => state.filters);

  const flagsOptions = [
    { title: "Vše", value: "all" },
    { title: "Jen aktivní", value: "active" },
    { title: "Jen archivované", value: "archived" },
  ];
  const sortOptions = [
    { title: "Podle času", value: "byDate" },
    { title: "Podle podpory", value: "byScore" },
  ];
  const typeOptions = [
    { title: "Návrhy i příspěvky", value: "all" },
    { title: "Jen návrhy", value: "proposalsOnly" },
    { title: "Jen příspěvky", value: "discussionOnly" },
  ];

  const setFilter = (prop, newValue) => {
    PostStore.update((state) => {
      state.filters[prop] = newValue;
      state.window.itemCount = state.window.items.length;

      updateWindowPosts(state);
    });
  };

  const onFlagsChange = (newValue) => setFilter("flags", newValue);
  const onSortChange = (newValue) => setFilter("sort", newValue, false);
  const onTypeChange = (newValue) => setFilter("type", newValue);

  return (
    <div className="flex flex-col space-y-2 xl:space-y-0 xl:space-x-8 xl:flex-row xl:items-center">
      <div className="-mx-1 joyride-filters">
        <Dropdown
          value={filters.flags}
          onChange={onFlagsChange}
          options={flagsOptions}
          className="text-xs ml-1 mt-2 xl:mt-0"
        />
        <Dropdown
          value={filters.sort}
          onChange={onSortChange}
          options={sortOptions}
          className="text-xs ml-1 mt-2 xl:mt-0"
        />
        <Dropdown
          value={filters.type}
          onChange={onTypeChange}
          options={typeOptions}
          className="text-xs ml-1 mt-2 xl:mt-0"
        />
      </div>
    </div>
  );
};

export default PostFilters;

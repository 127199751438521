import { useCallback, useState } from "react";

const baseActionParamsBuilder = (item, args) => {
  return item;
};

export const useItemActionConfirm = (actionFn, actionParamsBuilder = null) => {
  const [item, setItem] = useState(null);
  const [actionArgs, setActionArgs] = useState(null);

  const onActionConfirm = useCallback(
    async (args) => {
      if (item) {
        const newActionArgs = (actionParamsBuilder || baseActionParamsBuilder)(
          item,
          args,
        );
        setActionArgs(newActionArgs);
        const result = await actionFn.run(newActionArgs);

        if (!result.error) {
          setItem(null);
        }
      }
    },
    [item, setItem, actionFn, actionParamsBuilder, setActionArgs],
  );

  const onActionCancel = useCallback(() => {
    setItem(null);
  }, [setItem]);

  const [loading, error] = useActionState(actionFn, actionArgs);
  const unwrappedActionState = { loading, error };

  return [item, setItem, onActionConfirm, onActionCancel, unwrappedActionState];
};

export const useActionConfirm = (actionFn, actionArgs) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const onActionConfirm = useCallback(async () => {
    if (showConfirm) {
      const result = await actionFn.run(actionArgs);

      if (!result.error) {
        setShowConfirm(false);
      }
    }
  }, [showConfirm, setShowConfirm, actionFn, actionArgs]);

  const onActionCancel = useCallback(() => {
    setShowConfirm(false);
  }, [setShowConfirm]);

  return [showConfirm, setShowConfirm, onActionConfirm, onActionCancel];
};

export const useActionState = (actionFn, actionArgs) => {
  const { 0: started, 1: finished, 2: result } = actionFn.useWatch(actionArgs);
  return [started && !finished, result.error ? result.message : null];
};

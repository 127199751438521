import React from "react";

const AlreadyFinished = () => (
  <article className="container container--wide py-8 md:py-16 lg:py-32">
    <div className="flex">
      <div>
        <i className="ico--anchor text-2xl md:text-6xl lg:text-9xl mr-4 lg:mr-8"></i>
      </div>
      <div>
        <h1 className="head-alt-base md:head-alt-md lg:head-alt-xl mb-2">
          Jednání už skočilo!
        </h1>
        <p className="text-xl leading-snug">
          Oficiální program již skončil. Těšíme se na viděnou zase příště.
        </p>
      </div>
    </div>
  </article>
);

export default AlreadyFinished;

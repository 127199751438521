import has from "lodash/has";

import { AuthStore, PostStore } from "stores";

export const handleUserBanned = (payload) => {
  AuthStore.update((state) => {
    if (state.user && state.user.id && payload.id === state.user.id) {
      state.user.isBanned = true;
    }
  });

  PostStore.update((state) => {
    Object.keys(state.items).forEach((key) => {
      if (state.items[key].author.id === payload.id) {
        state.items[key].author.isBanned = true;
      }
    });
  });
};

export const handleUserUnbanned = (payload) => {
  AuthStore.update((state) => {
    if (state.user && state.user.id && payload.id === state.user.id) {
      state.user.isBanned = false;
    }
  });

  PostStore.update((state) => {
    Object.keys(state.items).forEach((key) => {
      if (state.items[key].author.id === payload.id) {
        state.items[key].author.isBanned = false;
      }
    });
  });
};

export const handleUserStatus = (payload) => {
  AuthStore.update((state) => {
    if (has(payload, "jitsi_allowed")) {
      state.showJitsiInvitePopup = payload.jitsi_allowed;
    }
  });
};

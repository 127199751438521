import has from "lodash/has";
import throttle from "lodash/throttle";

import { markdownConverter } from "markdown";
import { PostStore } from "stores";
import { parseRawPost, postsStateMapping, updateWindowPosts } from "utils";

/**
 * Re-apply sorting by rank but no more than once every 3 seconds.
 */
const sortOnRankThrottled = throttle(() => {
  PostStore.update((state) => {
    if (state.filters.sort === "byScore") {
      updateWindowPosts(state);
    }
  });
}, 5000);

export const handlePostRanked = (payload) => {
  PostStore.update((state) => {
    if (state.items[payload.id]) {
      state.items[payload.id].ranking.likes = payload["ranking_likes"];
      state.items[payload.id].ranking.dislikes = payload["ranking_dislikes"];
      state.items[payload.id].ranking.score =
        state.items[payload.id].ranking.likes -
        state.items[payload.id].ranking.dislikes;
    }
  });

  // Run sorting in a throttled manner.
  sortOnRankThrottled();
};

export const handlePostChanged = (payload) => {
  PostStore.update((state) => {
    if (state.items[payload.id]) {
      if (has(payload, "content")) {
        state.items[payload.id].content = payload.content;
        state.items[payload.id].contentHtml = markdownConverter.makeHtml(
          payload.content,
        );
        state.items[payload.id].modified = true;
      }

      if (has(payload, "state")) {
        state.items[payload.id].state = postsStateMapping[payload.state];
        updateWindowPosts(state);
      }

      if (has(payload, "is_archived")) {
        state.items[payload.id].archived = payload.is_archived;
        updateWindowPosts(state);
      }
    }
  });
};

export const handlePostCreated = (payload) => {
  PostStore.update((state) => {
    state.items[payload.id] = parseRawPost(payload);
    state.itemCount = Object.keys(state.items).length;
    updateWindowPosts(state);
  });
};

export const handlePostDeleted = (payload) => {
  PostStore.update((state) => {
    delete state.items[payload.id];
    updateWindowPosts(state);
  });
};

import React from "react";

import Button from "components/Button";

const BreakInProgress = () => (
  <article className="container container--wide py-8 md:py-16 lg:py-32">
    <div className="flex">
      <div>
        <i className="ico--clock text-2xl md:text-6xl lg:text-9xl mr-4 lg:mr-8"></i>
      </div>
      <div>
        <h1 className="head-alt-base md:head-alt-md lg:head-alt-xl mb-2">
          Probíhá přestávka ...
        </h1>
        <p className="text-xl leading-snug mb-8">
          Jednání celostátního fóra je momentálně přerušeno. Můžete si ale
          zobrazit program.
        </p>
        <Button
          routerTo="/program"
          className="md:text-lg lg:text-xl"
          hoverActive
        >
          Zobrazit program
        </Button>
      </div>
    </div>
  </article>
);

export default BreakInProgress;

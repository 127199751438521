import React, { useState } from "react";

import Button from "components/Button";
import { Card, CardActions, CardBody, CardHeadline } from "components/cards";
import ErrorMessage from "components/ErrorMessage";
import MarkdownEditor from "components/mde/MarkdownEditor";
import Modal from "components/modals/Modal";

const PostEditModal = ({
  post,
  onCancel,
  onConfirm,
  confirming,
  error,
  ...props
}) => {
  const [text, setText] = useState(post.content);
  const [textError, setTextError] = useState(null);

  const onTextInput = (newText) => {
    setText(newText);

    if (newText !== "") {
      if (newText.length >= 1024) {
        setTextError("Maximální délka příspěvku je 1024 znaků.");
      } else {
        setTextError(null);
      }
    }
  };

  const confirm = (evt) => {
    evt.preventDefault();

    if (!!text) {
      onConfirm(text);
    } else {
      setTextError("Před upravením příspěvku nezapomeňte vyplnit jeho obsah.");
    }
  };

  return (
    <Modal containerClassName="max-w-xl" onRequestClose={onCancel} {...props}>
      <form onSubmit={confirm}>
        <Card className="elevation-21">
          <CardBody>
            <div className="flex items-center justify-between mb-4">
              <CardHeadline>Upravit text příspěvku</CardHeadline>
              <button onClick={onCancel} type="button">
                <i className="ico--cross"></i>
              </button>
            </div>
            <MarkdownEditor
              value={text}
              onChange={onTextInput}
              error={textError}
              placeholder="Vyplňte text příspěvku"
              toolbarCommands={[
                ["header", "bold", "italic", "strikethrough"],
                ["link", "quote"],
                ["unordered-list", "ordered-list"],
              ]}
            />
            {error && (
              <ErrorMessage className="mt-2">
                Při editaci došlo k problému: {error}
              </ErrorMessage>
            )}
          </CardBody>
          <CardActions right className="space-x-1">
            <Button
              hoverActive
              color="blue-300"
              className="text-sm"
              disabled={textError || confirming}
              loading={confirming}
              onClick={confirm}
            >
              Uložit
            </Button>
            <Button
              hoverActive
              color="red-600"
              className="text-sm"
              onClick={onCancel}
            >
              Zrušit
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
};

export default PostEditModal;

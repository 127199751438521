import React, { useState } from "react";

import Button from "components/Button";
import { Card, CardActions, CardBody, CardHeadline } from "components/cards";
import Modal from "components/modals/Modal";

const ProgramEntryEditModal = ({
  programEntry,
  onCancel,
  onConfirm,
  ...props
}) => {
  const [text, setText] = useState(programEntry.title);

  const onTextInput = (evt) => {
    setText(evt.target.value);
  };

  const confirm = (evt) => {
    if (!!text) {
      onConfirm(text);
    }
  };

  return (
    <Modal containerClassName="max-w-md" onRequestClose={onCancel} {...props}>
      <Card className="elevation-21">
        <CardBody>
          <div className="flex items-center justify-between mb-4">
            <CardHeadline>Upravit název programového bodu</CardHeadline>
            <button onClick={onCancel}>
              <i className="ico--cross"></i>
            </button>
          </div>
          <div className="form-field">
            <label className="form-field__label" htmlFor="field">
              Nový název
            </label>
            <div className="form-field__wrapper form-field__wrapper--shadowed">
              <input
                type="text"
                className="text-input form-field__control"
                value={text}
                onChange={onTextInput}
                placeholder="Vyplňte nový název"
              />
            </div>
          </div>
        </CardBody>
        <CardActions right className="space-x-1">
          <Button
            hoverActive
            color="blue-300"
            className="text-sm"
            onClick={confirm}
          >
            Uložit
          </Button>
          <Button
            hoverActive
            color="red-600"
            className="text-sm"
            onClick={onCancel}
          >
            Zrušit
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default ProgramEntryEditModal;

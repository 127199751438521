import React from "react";
import classNames from "classnames";

const DropdownMenuItem = ({
  icon,
  className,
  onClick,
  title,
  titleClass,
  iconSize = "2xs",
  titleSize = "xs",
}) => {
  const iconCls = classNames("text-2xs mr-2", `text-${iconSize}`, icon);
  const titleCls = classNames(`text-${titleSize}`, titleClass);
  const cls = classNames(
    "dropdown__content-item bg-white hover:bg-grey-125 cursor-pointer",
    className
  );

  return (
    <li className={cls} onClick={onClick}>
      <span className="block px-3 py-3">
        <i className={iconCls}></i>
        <span className={titleCls}>{title}</span>
      </span>
    </li>
  );
};

export default React.memo(DropdownMenuItem);

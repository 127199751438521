import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { activateProgramPoint } from "actions/program";
import Button from "components/Button";
import Chip from "components/Chip";
import ModalConfirm from "components/modals/ModalConfirm";
import { useActionState, useItemActionConfirm } from "hooks";
import { AuthStore, ProgramStore } from "stores";

const Schedule = () => {
  const { isAuthenticated, user } = AuthStore.useState();
  const { currentId, scheduleIds, items } = ProgramStore.useState();
  const [
    entryToActivate,
    setEntryToActivate,
    onActivateConfirm,
    onActivateCancel,
  ] = useItemActionConfirm(activateProgramPoint);

  const [activating, activationError] = useActionState(
    activateProgramPoint,
    entryToActivate
  );

  return (
    <>
      <Helmet>
        <title>Program zasedání | CF 2024 | Pirátská strana</title>
        <meta
          name="description"
          content="Přečtěte si program on-line zasedání Celostátního fóra České pirátské strany, 13. 1. 2024."
        />
        <meta
          property="og:title"
          content="Program zasedání | CF 2024 | Pirátská strana"
        />
        <meta
          property="og:description"
          content="Přečtěte si program on-line zasedání Celostátního fóra České pirátské strany, 13. 1. 2024."
        />
      </Helmet>
      <article className="container container--default py-8 lg:py-24">
        <h1 className="head-alt-md lg:head-alt-lg mb-8">Program zasedání</h1>
        <div class="my-4">
           Program zde neobsahuje z technických důvodů všechny podrobnosti. Kompletní program naleznete na <a href="https://cf2024.pirati.cz/program">webu</a>.
        </div>
        <div className="flex flex-col">
          {scheduleIds.map((id) => {
            const isCurrent = id === currentId;
            const entry = items[id];
            const htmlContent = entry.htmlContent
              ? {
                  __html: entry.htmlContent,
                }
              : null;
            return (
              <div
                className="flex flex-col md:flex-row my-4 duration-300 text-black"
                key={entry.id}
              >
                <div className="w-28 md:text-right">
                  {isCurrent && (
                    <Chip condensed className="mt-2 mr-2" color="red-600">
                      Právě probíhá
                    </Chip>
                  )}
                </div>
                <div className="w-full md:w-32 flex flex-row md:flex-col items-center md:items-stretch md:text-right md:pr-8">
                  <p className="head-allcaps-2xs md:head-heavy-base">
                    {format(entry.expectedStartAt, "H:mm")}
                  </p>
                  <p className="ml-auto md:ml-0 head-allcaps-2xs md:head-heavy-xs md:text-grey-200 whitespace-no-wrap">
                    {format(entry.expectedStartAt, "d. M. Y")}
                  </p>
                </div>
                <div className="flex-grow w-full">
                  <h2 className="head-heavy-xs md:head-heavy-base mb-2">
                    {isCurrent && <Link to="/">{entry.fullTitle}</Link>}
                    {!isCurrent && entry.fullTitle}
                  </h2>
                  <div className="leading-snug">
                    <div className="space-x-2">
                      <strong>Navrhovatel:</strong>
                      <span>{entry.proposer}</span>
                    </div>
                    {entry.speakers && (
                      <div className="space-x-2">
                        <strong>Řečníci:</strong>
                        <span>{entry.speakers}</span>
                      </div>
                    )}
                  </div>
                  {htmlContent && (
                    <div
                      className="mt-2 leading-snug max-w-3xl content-block"
                      dangerouslySetInnerHTML={htmlContent}
                    />
                  )}
                  {isAuthenticated &&
                    user.role === "chairman" &&
                    entry.id !== currentId && (
                      <div className="mt-4">
                        <Button
                          onClick={() => setEntryToActivate(entry)}
                          color="grey-125"
                          className="text-xs"
                          fullwidth
                        >
                          Aktivovat tento bod programu
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            );
          })}
        </div>
        <ModalConfirm
          isOpen={!!entryToActivate}
          onConfirm={onActivateConfirm}
          onCancel={onActivateCancel}
          confirming={activating}
          error={activationError}
          title="Aktivovat bod programu?"
          yesActionLabel="Aktivovat"
        >
          Pogramovaný bod{" "}
          <strong>{entryToActivate && entryToActivate.title}</strong> bude
          aktivován. Chcete pokračovat?
        </ModalConfirm>
      </article>
    </>
  );
};

export default Schedule;

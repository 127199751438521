import Showdown from "showdown";
import xss from "xss";

const xssFilter = (converter) => [
  {
    type: "output",
    filter: (text) => xss(text),
  },
];

export const markdownConverter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: false,
  omitExtraWLInCodeBlocks: true,
  noHeaderId: true,
  headerLevelStart: 2,
  openLinksInNewWindow: true,
  extensions: [xssFilter],
});

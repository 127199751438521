import React from "react";
import classNames from "classnames";

const PostScore = ({
  postType,
  ranking,
  supportThreshold,
  rankingReadonly,
  className,
}) => {
  const { score, dislikes } = ranking;
  const highlight = postType === "procedure-proposal" && !rankingReadonly;
  const coloring = highlight
    ? {
        "bg-red-600 text-white": score < 0,
        "bg-grey-125 text-grey-200": score === 0 && score < supportThreshold,
        "bg-yellow-400 text-grey-300":
          score > 0 && dislikes > 0 && score < supportThreshold,
        "bg-green-400 text-white":
          score >= supportThreshold || (score > 0 && dislikes <= 0),
      }
    : "bg-grey-125 text-grey-200";

  let title;

  if (postType === "procedure-proposal") {
    if (rankingReadonly) {
      title = `Návrh postupu získal podporu ${score} hlasů.`;
    } else if (dislikes > 0) {
      if (score < supportThreshold) {
        title = `Aktuální podpora je ${score} hlasů, pro získání podpory skupiny členů chybí ještě ${
          supportThreshold - score
        }.`;
      } else {
        title = `Aktuální podpora je ${score} hlasů, což je dostatek pro získání podpory skupiny členů (vyžaduje alespoň ${supportThreshold} hlasů).`;
      }
    } else {
      title = `Příspěvek získal ${score} hlasů bez jakýchkoliv hlasů proti a má tedy konkludentní podporu.`;
    }
  } else {
    title = `Příspěvek získal podporu ${score} hlasů.`;
  }

  return (
    <span
      className={classNames(
        "p-1 text-sm flex items-center space-x-1",
        coloring,
        className
      )}
      style={{ cursor: "help" }}
      aria-label={title}
      data-tip={title}
      data-type="dark"
      data-place="top"
    >
      <i className="ico--power" />
      <span className="font-bold">{score}</span>
    </span>
  );
};

export default React.memo(PostScore);

import isNumber from "lodash/isNumber";

import { GlobalInfoStore } from "stores";

export const handleOnlineUsersUpdated = (payload) => {
  GlobalInfoStore.update((state) => {
    state.onlineUsers = isNumber(payload.all) ? payload.all : 0;
    state.onlineMembers = isNumber(payload.members) ? payload.members : 0;
    state.groupSizeHalf = isNumber(payload.group_size_half)
      ? payload.group_size_half
      : null;
  });
};

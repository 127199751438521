import React from "react";
import classNames from "classnames";

const Chip = ({
  className,
  color = "grey-125",
  condensed,
  hoveractive = false,
  children,
  ...props
}) => {
  const chipClass = classNames(
    "chip",
    {
      "chip--condensed": !!condensed,
      "chip--hoveractive": !!hoveractive,
    },
    `chip--${color}`,
    className
  );

  return (
    <span className={chipClass} {...props}>
      {children}
    </span>
  );
};

export default React.memo(Chip);

import React from "react";
import classNames from "classnames";

import Announcement from "./Announcement";

const AnnouncementList = ({
  items,
  className,
  canRunActions,
  onDelete,
  onEdit,
  onSeen,
}) => {
  const buildHandler = (responderFn) => (announcement) => (evt) => {
    evt.preventDefault();
    responderFn(announcement);
  };

  const onAnnouncementEdit = buildHandler(onEdit);
  const onAnnouncementDelete = buildHandler(onDelete);

  const onAnnouncementSeen = (announcement) => () => {
    onSeen(announcement);
  };

  const getClassName = (idx) => {
    if (idx === 0) {
      return "pt-4 lg:pt-8";
    }

    if (idx === items.length - 1) {
      return "pb-4 lg:pb-8";
    }

    return "";
  };

  return (
    <div className={classNames("space-y-px", className)}>
      {items.map((item, idx) => (
        <Announcement
          className={getClassName(idx)}
          key={item.id}
          datetime={item.datetime}
          type={item.type}
          content={item.contentHtml}
          link={item.link}
          seen={item.seen}
          canRunActions={canRunActions}
          onEdit={onAnnouncementEdit(item)}
          onDelete={onAnnouncementDelete(item)}
          onSeen={onAnnouncementSeen(item)}
        />
      ))}
      {!items.length && (
        <p className="px-8 py-4 leading-snug text-sm md:text-base">
          Zatím žádná oznámení.
        </p>
      )}
    </div>
  );
};

export default AnnouncementList;

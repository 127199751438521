import has from "lodash/has";

import { loadPosts } from "actions/posts";
import { markdownConverter } from "markdown";
import { ProgramStore } from "stores";

export const handleProgramEntryChanged = (payload) => {
  ProgramStore.update((state) => {
    const entry = state.items[payload.id];

    if (entry) {
      if (has(payload, "discussion_opened")) {
        state.items[payload.id].discussionOpened = payload.discussion_opened;
      }
      if (has(payload, "title")) {
        state.items[payload.id].title = payload.title;
        state.items[payload.id].fullTitle =
          entry.number !== "" ? `${entry.number}. ${entry.title}` : entry.title;
      }
      if (has(payload, "description")) {
        state.items[payload.id].description = payload.description;
        state.items[payload.id].htmlContent = markdownConverter.makeHtml(
          payload.description,
        );
      }

      if (has(payload, "is_live") && payload.is_live) {
        state.currentId = payload.id;
      }
    }
  });

  if (has(payload, "is_live") && payload.is_live) {
    // Re-load posts - these are bound directly to the program schedule entry.
    loadPosts.run({}, { respectCache: false });
  }
};

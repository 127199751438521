import React from "react";

import {
  deleteAnnouncement,
  loadAnnouncements,
  markSeen,
  updateAnnouncement,
} from "actions/announcements";
import AnnouncementEditModal from "components/annoucements/AnnouncementEditModal";
import AnnouncementList from "components/annoucements/AnnouncementList";
import { CardBody } from "components/cards";
import ErrorMessage from "components/ErrorMessage";
import ModalConfirm from "components/modals/ModalConfirm";
import { useItemActionConfirm } from "hooks";
import { AnnouncementStore, AuthStore } from "stores";

const AnnoucementsContainer = ({ className }) => {
  const { 2: loadResult } = loadAnnouncements.useWatch();

  const [
    itemToDelete,
    setItemToDelete,
    onDeleteConfirm,
    onDeleteCancel,
    deleteState,
  ] = useItemActionConfirm(deleteAnnouncement);

  const [
    itemToEdit,
    setItemToEdit,
    onEditConfirm,
    onEditCancel,
    editState,
  ] = useItemActionConfirm(updateAnnouncement, (item, payload) => ({
    item,
    payload,
  }));

  const { isAuthenticated, user } = AuthStore.useState();
  const items = AnnouncementStore.useState((state) =>
    state.itemIds.map((id) => state.items[id])
  );

  return (
    <div className={className}>
      {loadResult && loadResult.error && (
        <CardBody>
          <ErrorMessage>
            Oznámení se nepodařilo načíst: {loadResult.message}
          </ErrorMessage>
        </CardBody>
      )}
      <AnnouncementList
        items={items}
        canRunActions={isAuthenticated && user.role === "chairman"}
        onDelete={setItemToDelete}
        onEdit={setItemToEdit}
        onSeen={markSeen}
      />
      <ModalConfirm
        isOpen={!!itemToDelete}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        confirming={deleteState.loading}
        error={deleteState.error}
        title="Opravdu smazat?"
        yesActionLabel="Smazat"
      >
        Tato akce je nevratná. Opravdu chcete toto oznámení smazat?
      </ModalConfirm>
      {itemToEdit && (
        <AnnouncementEditModal
          isOpen={true}
          announcement={itemToEdit}
          onConfirm={onEditConfirm}
          onCancel={onEditCancel}
          confirming={editState.loading}
          error={editState.error}
        />
      )}
    </div>
  );
};

export default AnnoucementsContainer;

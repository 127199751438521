import React from "react";

import Button from "components/Button";

import ModalWithActions from "./ModalWithActions";

const ModalConfirm = ({
  title,
  children,
  yesActionLabel = "OK",
  cancelActionLabel = "Zrušit",
  onCancel,
  onConfirm,
  confirming,
  error,
  ...props
}) => {
  const actions = (
    <>
      <Button
        hoverActive
        color="blue-300"
        className="text-sm"
        onClick={onConfirm}
        loading={confirming}
      >
        {yesActionLabel}
      </Button>
      <Button
        hoverActive
        color="grey-125"
        className="text-sm"
        onClick={onCancel}
      >
        {cancelActionLabel}
      </Button>
    </>
  );

  return (
    <ModalWithActions
      onClose={onCancel}
      title={title}
      error={error}
      actions={actions}
      containerClassName="max-w-md"
      {...props}
    >
      {children}
    </ModalWithActions>
  );
};

export default React.memo(ModalConfirm);

import React from "react";
import classNames from "classnames";

const ErrorMessage = ({ className, children }) => {
  return (
    <div className={classNames("text-red-600 font-bold", className)}>
      {children}
    </div>
  );
};

export default React.memo(ErrorMessage);

import React from "react";

const Beacon = React.forwardRef(({ onClick }, ref) => (
  <span
    className="relative inline-flex h-8 w-8"
    title="Jsi online"
    ref={ref}
    onClick={onClick}
  >
    <span className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-red-600"></span>
    <span className="inline-flex rounded-full w-8 h-8 bg-red-600"></span>
  </span>
));

export default Beacon;

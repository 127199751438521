import React from "react";
import Modal from "react-modal";
import classNames from "classnames";

const CustomModal = ({ children, containerClassName, ...props }) => (
  <Modal
    contentLabel={props.headline}
    overlayClassName="modal__overlay"
    className="modal__content"
    {...props}
  >
    <div
      className={classNames(
        "modal__container w-full flex items-center justify-center",
        containerClassName
      )}
    >
      <div className="modal__container-body w-full">{children}</div>
    </div>
  </Modal>
);

export default CustomModal;

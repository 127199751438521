import hex from "crypto-js/enc-hex";
import hmacSHA1 from "crypto-js/hmac-sha1";
import WaitQueue from "wait-queue";

import { AuthStore, GlobalInfoStore } from "stores";

import { handlers } from "./handlers";

function Worker() {
  const queue = new WaitQueue();

  const doLoop = async () => {
    const event = await queue.shift();
    messageRouter(event);
    setTimeout(doLoop);
  };

  const messageRouter = (event) => {
    console.debug("[ws][worker] New message", event.data);

    try {
      const data = JSON.parse(event.data);

      if (!data.event) {
        return console.error("[ws][worker] Missing `event` field");
      }

      const handlerFn = handlers[data.event];

      if (!handlerFn) {
        return console.warn(`[ws][worker] Can't handle event '${data.event}'`);
      }

      handlerFn(data.payload || {});
    } catch (err) {
      console.error("[ws][worker] Could not parse message.", err);
    }
  };

  return {
    queue,
    start: () => {
      console.debug("[ws][worker] Start processing messages.");
      doLoop();
    },
  };
}

const buildKeepalivePayload = async () => {
  const { user } = AuthStore.getRawState();
  const payload = user && user.id ? user.id.toString() : "";
  const signature =
    user && !!user.secret ? hmacSHA1(payload, user.secret) : null;

  return {
    event: "KEEPALIVE",
    payload,
    sig: signature ? hex.stringify(signature) : null,
  };
};

export const connect = ({ url, onConnect }) => {
  return new Promise((resolve, reject) => {
    const worker = Worker();

    GlobalInfoStore.update((state) => {
      state.connectionState = "connecting";
    });
    const ws = new WebSocket(url);

    let keepAliveInterval;
    console.log("[ws] Connecting ...");

    ws.onopen = () => {
      GlobalInfoStore.update((state) => {
        state.connectionState = "connected";
      });
      console.log("[ws] Connected.");

      const sendKeepalive = async () => {
        ws.send(JSON.stringify(await buildKeepalivePayload()));
        console.debug("[ws] Sending keepalive.");
      };

      sendKeepalive();

      keepAliveInterval = setInterval(sendKeepalive, 15 * 1000);

      const self = { ws, worker, sendKeepalive };

      if (onConnect) {
        return onConnect(self).then(() => resolve(self));
      }

      return resolve(self);
    };

    ws.onmessage = worker.queue.push.bind(worker.queue);

    ws.onclose = (event) => {
      GlobalInfoStore.update((state) => {
        state.connectionState = "offline";
      });
      console.log(
        "[ws] Socket is closed. Reconnect will be attempted in 1 second.",
        event.reason,
      );

      clearInterval(keepAliveInterval);
      setTimeout(() => connect({ url, onConnect }), 1000);
    };

    ws.onerror = (err) => {
      console.error(
        "[ws] Socket encountered error: ",
        err.message,
        "Closing socket",
      );
      ws.close();
      reject(err);
    };
  });
};

import React from "react";
import { Link } from "react-router-dom";
import { format, isToday } from "date-fns";
import pick from "lodash/pick";

import { GlobalInfoStore, ProgramStore } from "stores";

const GlobalStats = () => {
  const {
    onlineUsers,
    onlineMembers,
    groupSizeHalf,
  } = GlobalInfoStore.useState((state) =>
    pick(state, ["onlineUsers", "onlineMembers", "groupSizeHalf"])
  );
  const { currentId, scheduleIds, items } = ProgramStore.useState((state) =>
    pick(state, ["currentId", "scheduleIds", "items"])
  );

  const nextProgramEntryId = scheduleIds
    ? scheduleIds[currentId ? scheduleIds.indexOf(currentId) + 1 : 0]
    : null;

  const nextProgramEntry = nextProgramEntryId
    ? items[nextProgramEntryId]
    : null;

  const nextProgramEntryCaption = nextProgramEntry
    ? `${nextProgramEntry.title} @ ${format(
        nextProgramEntry.expectedStartAt,
        isToday(nextProgramEntry.expectedStartAt) ? "H:mm" : "dd. MM. H:mm"
      )}`
    : null;

  return (
    <div className="bg-grey-50 flex space-x-4 leading-normal px-4 py-2 text-2xs md:text-xs lg:text-sm text-grey-300 whitespace-no-wrap">
      <div
        data-tip="Počet přihlášených členů Pirátské strany."
        data-tip-at="bottom"
      >
        <strong>{onlineMembers}</strong>{" "}
        <span>
          {onlineMembers === 1 && "člen online"}
          {onlineMembers > 1 && onlineMembers <= 4 && "členové online"}
          {(onlineMembers === 0 || onlineMembers > 4) && "členů online"}
        </span>
      </div>
      <div
        data-tip="Celkový počet osob, které mají tuto stránku otevřenou."
        data-tip-at="bottom"
      >
        <strong>{onlineUsers}</strong> <span>online celkem</span>
      </div>
      {groupSizeHalf !== null && (
        <div
          data-tip="Velikost skupiny členů je důležitá při posuzování podpory návrhů postupu."
          data-tip-at="bottom"
        >
          <span>Vel. skupiny členů je</span> <strong>{groupSizeHalf}</strong>
        </div>
      )}
      {nextProgramEntry && (
        <div className="flex-grow text-right hidden sm:block lg:hidden xl:block truncate">
          Následuje:{" "}
          <Link
            to="/program"
            className="font-bold"
            aria-label={nextProgramEntryCaption}
            data-tip={"Následuje bod " + nextProgramEntryCaption}
            data-tip-at="bottom"
          >
            {nextProgramEntryCaption}
          </Link>
        </div>
      )}
    </div>
  );
};

export default GlobalStats;

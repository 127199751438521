import React, { useState } from "react";
import ReactMde from "react-mde";
import classNames from "classnames";

import { markdownConverter } from "markdown";

import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "./MarkdownEditor.css";

const MarkdownEditor = (
  { value, onChange, error, placeholder = "", ...props },
  ref
) => {
  const [selectedTab, setSelectedTab] = useState("write");

  const classes = {
    preview: "p-2 content-block text-input text-sm md:text-base",
    textArea: "p-2 text-input text-sm md:text-base",
  };

  const l18n = {
    write: "Psaní",
    preview: "Náhled",
    uploadingImage: "Nahrávám obrázek",
  };

  const childProps = {
    textArea: {
      placeholder,
    },
  };

  return (
    <div className={classNames("form-field", { "form-field--error": !!error })}>
      <ReactMde
        ref={ref}
        value={value}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(markdownConverter.makeHtml(markdown))
        }
        classes={classes}
        l18n={l18n}
        childProps={childProps}
        {...props}
      />
      {error && <div className="form-field__error">{error}</div>}
    </div>
  );
};

export default React.forwardRef(MarkdownEditor);

import React from "react";

import {
  Card,
  CardActions,
  CardBody,
  CardBodyText,
  CardHeadline,
} from "components/cards";
import ErrorMessage from "components/ErrorMessage";

import Modal from "./Modal";

const ModalConfirm = ({
  title,
  children,
  actions,
  error,
  onClose,
  ...props
}) => {
  return (
    <Modal onRequestClose={onClose} {...props}>
      <Card className="elevation-21">
        <CardBody>
          <div className="flex items-center justify-between mb-4">
            <CardHeadline>{title}</CardHeadline>
            <button
              onClick={onClose}
              type="button"
              data-tip="Zavřít"
              aria-label="Zavřít"
            >
              <i className="ico--cross"></i>
            </button>
          </div>
          <CardBodyText>{children}</CardBodyText>
          {error && (
            <ErrorMessage className="mt-2">
              Při provádění akce došlo k problému: {error}
            </ErrorMessage>
          )}
        </CardBody>
        <CardActions right className="space-x-1">
          {actions}
        </CardActions>
      </Card>
    </Modal>
  );
};

export default React.memo(ModalConfirm);

import React from "react";
import classNames from "classnames";

const Dropdown = ({ value, options, onChange, className }) => {
  const onSelectChanged = (evt) => {
    onChange(evt.target.value);
  };

  return (
    <span
      className={classNames(
        "chip chip--grey-125 chip--select chip--hoveractive",
        className
      )}
    >
      <select onChange={onSelectChanged} value={value}>
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.title}
          </option>
        ))}
      </select>
      <span className="chip__icon ico--chevron-down"></span>
    </span>
  );
};

export default React.memo(Dropdown);

import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const Button = ({
  className,
  bodyClassName,
  icon,
  color = "black",
  hoverActive = true,
  fullwidth = false,
  loading = false,
  children,
  routerTo,
  bodyProps = {},
  ...props
}) => {
  const btnClass = classNames(
    "btn",
    `btn--${color}`,
    {
      "btn--icon": !!icon,
      "btn--hoveractive": hoverActive,
      "btn--fullwidth md:btn--autowidth": fullwidth,
      "btn--loading": loading,
    },
    className
  );

  const bodyClass = classNames("btn__body", bodyClassName);

  const inner = (
    <div className="btn__body-wrap">
      <div className={bodyClass} {...bodyProps}>
        {children}
      </div>
      {!!icon && (
        <div className="btn__icon">
          <i className={icon}></i>
        </div>
      )}
    </div>
  );

  if (routerTo) {
    return (
      <NavLink to={routerTo} className={btnClass} {...props}>
        {inner}
      </NavLink>
    );
  }

  return (
    <button className={btnClass} {...props}>
      {inner}
    </button>
  );
};

export default React.memo(Button);
